import React, { useContext } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import { SearchContext } from "../../context/SearchContext";

const Trip = ({ tripModel, indexReturn }) => {
  const { selectedRoute } = useContext(SearchContext);
  const { departure_date, originPort, destinationPort } = tripModel;

  console.log(tripModel);

  const dateParsing = (date) => moment(date).format("MMM Do YY");
  const returningHour = (date) => {
    const origin = tripModel.originPort.replace(" ", "");
    const destination = tripModel.destinationPort.replace(" ", "");

    if (origin == "Utila" && destination == "LaCeiba") {
      return moment(date).add(.75, "hours").format("LT");
    } else if (origin == "LaCeiba" && destination == "Utila") {
      return moment(date).add(.75, "hours").format("LT");
    } else {
      return moment(date).add(1.5, "hours").format("LT");
    }
  };
  const hourParse = (date) => moment(date).format("LT");

  const routeClass =
    selectedRoute === "One Way" ? "route clearfix full-size" : "route clearfix";
  return (
    <div className={routeClass}>
      <h3>
        {indexReturn ? "Return" : "Depart"} — {dateParsing(departure_date)}
      </h3>
      <div className="info">
        <p className="hour">
          {hourParse(departure_date)}
          <span>{originPort}</span>
        </p>
        <div className="arrow">
          <span className="ib" />
          <i className="icon-chevron-thin-right" />
        </div>
        <p className="hour">
          {returningHour(departure_date)}
          <span>{destinationPort}</span>
        </p>
      </div>
    </div>
  );
};

Trip.propTypes = {
  tripModel: PropTypes.object.isRequired,
};

export default Trip;
